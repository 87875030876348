/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type Named, Resource } from 'ember-resources';

import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type Conversation from 'embercom/objects/inbox/conversation';

type SelectionResourceArgs = { inbox: Inbox[] };
export type ConversationEntry = Conversation | ConversationTableEntry | ConversationSummary;

export default class ConversationSelectionResource extends Resource<Named<SelectionResourceArgs>> {
  @tracked conversationObjects: Array<ConversationEntry> = [];

  constructor(
    owner: any,
    args: Named<SelectionResourceArgs>,
    previous: ConversationSelectionResource,
  ) {
    super(owner, args, previous);

    if (previous) {
      this.conversationObjects = previous.conversationObjects;

      if (args.named.inbox !== previous.args.named.inbox) {
        this.conversationObjects = [];
      }
    }
  }

  get count() {
    return this.conversationObjects.length;
  }

  get ids() {
    return this.conversationObjects.map((convo) => convo.id);
  }

  @action toggle(
    conversation: ConversationEntry,
    allConversations?: ConversationEntry[],
    event?: PointerEvent,
  ) {
    if (event?.shiftKey && this.conversationObjects.length && allConversations) {
      this.toggleInBulk(conversation, allConversations);
      return;
    }

    let foundConvo = this.conversationObjects.some((convo) => convo.id === conversation.id);
    if (foundConvo) {
      this.conversationObjects = this.conversationObjects.filter(
        (convo) => convo.id !== conversation.id,
      );
    } else {
      this.conversationObjects = [...this.conversationObjects, conversation];
    }
  }

  @action toggleAll(conversations: Array<ConversationEntry>) {
    let ids = this.conversationObjects.map((convo) => convo.id);
    let hasAllSelected = conversations.every((convo) => ids.includes(convo.id));

    if (hasAllSelected) {
      this.conversationObjects = [];
    } else {
      this.conversationObjects = conversations;
    }
  }

  private indexedConversation(
    selectedConversation: ConversationEntry,
    allConversations: ConversationEntry[],
  ) {
    return {
      ...selectedConversation,
      index: allConversations.findIndex((convo) => convo.id === selectedConversation.id),
    };
  }

  @action toggleInBulk(
    selectedConversation: ConversationEntry,
    allConversations: ConversationEntry[],
  ) {
    let lastOfPreviouslySelectedIndex = this.conversationObjects.length
      ? this.conversationObjects
          .map((convo) => this.indexedConversation(convo, allConversations))
          .sortBy('index')[this.conversationObjects.length - 1].index
      : 0;

    let newlySelectedConversationIndex = this.indexedConversation(
      selectedConversation,
      allConversations,
    ).index;

    let sliceIndexes =
      lastOfPreviouslySelectedIndex > newlySelectedConversationIndex
        ? [newlySelectedConversationIndex, lastOfPreviouslySelectedIndex + 1]
        : [lastOfPreviouslySelectedIndex, newlySelectedConversationIndex + 1];

    let conversationsToUpdate = allConversations.slice(...sliceIndexes);

    let previouslySelectedConversationIds = new Set(
      this.conversationObjects.map((convo) => convo.id),
    );
    let conversationsToUpdateIds = new Set(conversationsToUpdate.map((convo) => convo.id));

    let canDeselectConversations = conversationsToUpdate.every((convo) =>
      previouslySelectedConversationIds.has(convo.id),
    );

    if (canDeselectConversations) {
      this.conversationObjects = this.conversationObjects.filter(
        (convo) => !conversationsToUpdateIds.has(convo.id),
      );
    } else {
      this.conversationObjects = [
        ...this.conversationObjects,
        ...conversationsToUpdate.filter(
          (convo) => !previouslySelectedConversationIds.has(convo.id),
        ),
      ];
    }
  }

  @action clear() {
    this.conversationObjects = [];
  }
}
