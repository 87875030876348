/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import { resource, resourceFactory } from 'ember-resources/util/function-resource';
import { waitForPromise } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';

import { Resource } from 'ember-resources/core';
import { type Positional } from 'ember-resources/core/types';

type Fn = (...args: any[]) => any;
class State<T> {
  @tracked value?: T;
  @tracked error?: unknown;
  @tracked isLoading = false;
  @tracked isFirstLoad = true;

  private fn: Fn;
  private internalPromise?: Promise<void>;

  controller?: AbortController;

  constructor(fn: Fn, isFirstLoad = true) {
    this.fn = fn;
    this.isFirstLoad = isFirstLoad;
  }

  get promise() {
    return this.internalPromise;
  }

  cleanup() {
    this.controller?.abort();
  }

  /**
   * update replaces the value of remote state with a new value.
   */
  update = (value: T) => {
    this.value = value;
  };

  /**
   * reload will re-run the function.
   */
  reload = (...args: any[]) => {
    this.executeFn(args);
  };

  private executeFn(args: any[]) {
    this.isLoading = true;

    this.controller?.abort();
    let controller = (this.controller = new AbortController());

    this.internalPromise = Promise.resolve(this.fn({ signal: controller.signal, args }))
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((value: T) => {
        this.error = undefined;
        this.value = value;
      })
      .catch((error) => {
        this.value = undefined;

        // AbortError is expected when we cancel the request
        // so we can ignore it.
        if (error.name === 'AbortError') {
          this.error = undefined;
        } else {
          console.error(error);
          this.error = error;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });

    waitForPromise(this.internalPromise);
  }
}

/**
  Async Data is an abstraction built using ember-resources that gives us some commonly used
  state and utilities for loading data asynchronously.

  Its API is similar to trackedTask and trackedFunction, but not exactly the same. The two
  two differences are the ability to reload data and to update the data locally, which makes
  it the preferable option over the other two.

  It will re-run when any tracked data it consumes changes.

  Example:
  ```
    @use data = AsyncData<Data>(({ signal }) => {
      return fetch('/api/data', { signal });
    });
  ```

  `Data` here is the type that you're expecting from the request.

  `signal` is an optional AbortSignal that you can use to cancel the request
  when the resource is destroyed.
*/
export function AsyncData<T>(fn: Fn) {
  let isFirstLoad = true;

  return resource(({ on }) => {
    let state = new State<T>(fn, isFirstLoad);
    isFirstLoad = false;

    state.reload();
    on.cleanup(() => state.cleanup());
    return state;
  });
}

resourceFactory(AsyncData);

class DeduplicatedAsyncDataClass<
  R extends ReturnType<F>,
  A extends [any, ...any[]],
  F extends (...args: [...A, RequestInit]) => any,
> extends Resource<Positional<[() => A, F]>> {
  @tracked value?: R;
  @tracked error?: unknown;
  @tracked isLoading = false;
  @tracked performCount = 0;

  private fn?: Fn;
  private lastArgs: any[] = [];
  private controller?: AbortController;
  private internalPromise?: Promise<void>;

  constructor(owner: any) {
    super(owner);

    registerDestructor(this, () => {
      this.controller?.abort();
    });
  }

  get isFirstLoad() {
    return this.performCount === 0;
  }

  get promise() {
    return this.internalPromise;
  }

  modify([thunk, fn]: [() => A, F]) {
    let currentArgs = thunk();
    this.fn = fn;

    let meaningfulChange = currentArgs.any((arg: any, index: any) => arg !== this.lastArgs[index]);
    if (meaningfulChange) {
      this.executeFn(...currentArgs);
    }
    this.lastArgs = [...currentArgs];
  }

  update(value: any) {
    this.value = value;
  }

  reload(...args: any[]) {
    if (args.length) {
      this.lastArgs = [...args];
    }
    this.executeFn(...this.lastArgs);
  }

  private executeFn(...args: any[]) {
    this.isLoading = true;
    this.controller?.abort();
    let controller = (this.controller = new AbortController());

    this.internalPromise = Promise.resolve(this.fn?.(...args, { signal: controller.signal }))
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((value: any) => {
        this.error = undefined;
        this.value = value;
      })
      .catch((error) => {
        this.value = undefined;

        // AbortError is expected when we cancel the request
        // so we can ignore it.
        if (error.name === 'AbortError') {
          this.error = undefined;
        } else {
          console.error(error);
          this.error = error;
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.performCount++;
      });

    waitForPromise(this.internalPromise);
  }
}

export function DeduplicatedAsyncData<
  A extends [any, ...any[]],
  F extends (...args: [...A, RequestInit]) => any,
>(context: object, thunk: () => A, fn: F) {
  return DeduplicatedAsyncDataClass.from<DeduplicatedAsyncDataClass<Awaited<ReturnType<F>>, A, F>>(
    context,
    () => [thunk, fn],
  );
}
