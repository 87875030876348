/* RESPONSIBLE TEAM: team-channels */

import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { captureException } from 'embercom/lib/sentry';
import type InboxApi from 'embercom/services/inbox-api';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { type TaskArgs } from '@glint/environment-ember-loose/registry';
import { restartableTask } from 'ember-concurrency-decorators';
import { type Awaited } from 'embercom/lib/inbox2/types';
import { type BlockList } from '@intercom/interblocks.ts';
import { tracked } from '@glimmer/tracking';

interface EmailHistoryApiArgs {
  conversationId: number;
}

export default class EmailHistoryApi extends Resource<Named<EmailHistoryApiArgs>> {
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;
  private lastConversationId?: number;

  @tracked historyBlocks: BlockList | undefined = undefined;
  @tracked historyMetadataId: number | undefined = undefined;
  @tracked historyFromExternalSender = false;
  historyConversationId: number | undefined;

  constructor(owner: unknown) {
    super(owner);
    registerDestructor(this, this.teardown);
  }

  modify(_: never, args: EmailHistoryApiArgs) {
    // When the conversation changes, cancel any running tasks
    if (this.lastConversationId !== args.conversationId) {
      this.teardown();
    }

    this.lastConversationId = args.conversationId;
  }

  get isWorking() {
    return taskFor(this.loadHistoryTask).isRunning;
  }

  @action loadHistory(...args: TaskArgs<EmailHistoryApi['loadHistoryTask']>) {
    return taskFor(this.loadHistoryTask).perform(...args);
  }

  @action private teardown() {
    taskFor(this.loadHistoryTask).cancelAll();
    this.resetParams();
  }

  resetParams() {
    this.historyBlocks = undefined;
    this.historyMetadataId = undefined;
    this.historyFromExternalSender = false;
  }

  @restartableTask
  private *loadHistoryTask(conversationId: number, conversationPartId: number | undefined) {
    try {
      let { responseConversationId, history } = (yield this.inboxApi.fetchConversationHistory(
        conversationId,
        conversationPartId,
      )) as Awaited<ReturnType<InboxApi['fetchConversationHistory']>>;

      if (responseConversationId !== this.lastConversationId) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'load_email_history',
          object: 'wrong_history',
          history_conversation_id: responseConversationId,
          current_conversation_id: this.lastConversationId,
        });
      }

      if (responseConversationId === this.lastConversationId && history.metadata_id) {
        this.historyConversationId = responseConversationId;
        this.historyBlocks = history.blocks;
        this.historyMetadataId = history.metadata_id;
        this.historyFromExternalSender = history.from_external_sender;
      }
    } catch (error) {
      captureException(error);
      throw error;
    }
  }
}
