/* RESPONSIBLE TEAM: team-help-desk-experience */

import { registerDestructor } from '@ember/destroyable';
import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Tag from 'embercom/objects/inbox/tag';
import type UserApi from 'embercom/services/user-api';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';

interface ResourceArgs {
  userId: string;
  onLoadTagsStarted?: () => void;
}

/* eslint-disable @intercom/intercom/no-component-inheritance */
export default class UserTagsResource extends Resource<Named<ResourceArgs>> {
  @service declare userApi: UserApi;
  @service declare notificationsService: any;
  @tracked tags: Tag[] = [];
  private args?: ResourceArgs = undefined;

  constructor(owner: any) {
    super(owner);

    registerDestructor(this, () => {
      taskFor(this.loadTags).cancelAll();
    });
  }

  modify(_: unknown, args: ResourceArgs) {
    let userId = args.userId;
    if (userId !== this.args?.userId) {
      this.args = args;
      taskFor(this.loadTags).perform(userId, args.onLoadTagsStarted);
    }
  }

  get isLoading() {
    return taskFor(this.loadTags).isRunning;
  }

  @task *loadTags(userId: string, onLoadTagsStarted?: () => void): TaskGenerator<void> {
    if (!userId) {
      return;
    }

    onLoadTagsStarted?.();

    try {
      this.tags = yield this.userApi.fetchUserTags(userId);
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      return;
    }
  }
}
